import React, { useState } from 'react'
import { H4, PrimaryButton, H6, Modal, Button } from '../../../components'
import { initiatePrivileged } from '../../../util/api';

import css from './Listing.module.css'
import { BeatLoader } from 'react-spinners';
import { array } from 'prop-types';
import classNames from 'classnames';

const ServiceCard = (props) => {
  const { service, onOrder, isRushDelivery, isOnline, isModalOpen, setIsModalOpen } = props;
  const { amount, description, title } = service;

  const isRushDeliveryService = service.type === 'rush-delivery';

  const centsToDollar = amount ? (amount / 100) : null;

  return <div className={css.serviceCard}>
    <div>
      <H4 className={css.titleAmount}>{title}</H4>
      <p className={css.descriptionText}>{description}</p>
    </div>
    <div>
      <PrimaryButton
        className={classNames({
          [css.orderBtn]: true,
          [css.advisorOffline]: isRushDeliveryService && !isOnline,
          [css.rushService]: isRushDeliveryService && isOnline
        }
        )}
        onClick={() => isRushDeliveryService ? (isOnline ? onOrder(service.title) : setIsModalOpen(true)) : onOrder(service.title)}
      >
        Order For ${centsToDollar}
      </PrimaryButton>
    </div>
  </div>
}



export default function AdvisorServices(props) {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onManageDisableScrolling = () => {
    console.log();
  }

  const {
    services,
    ensuredUser,
    intl,
    onOrder,
    listingFieldsInProgress,
    isOnline,
    ensuredListing,
    ensuredCurrentUser,
    onInquireRushDelivery,
    isOwnListing,
    initiatePrivilegedInProgress,
    initiatePrivilegedError,
    initiatePrivileged
  } = props;
  const { isRushDelivery } = ensuredUser.attributes.profile.publicData;

  const listingId = ensuredListing?.id?.uuid;
  const customerId = ensuredCurrentUser?.id?.uuid;

  const sortedServices = services.sort((a, b) => a.validity - b.validity);
  const servicesTitle = intl.formatMessage({ id: 'CustomListingPage.Listing.authorServicesTitle' });

  const servicesContent = !listingFieldsInProgress
    ? sortedServices.map((s, i) => (
      s && s.amount !== 0 ? <ServiceCard service={s}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isOnline={isOnline}
        isRushDelivery={isRushDelivery}
        key={i} onOrder={onOrder} />
        : null
    )) : (
      <div className={css.servicesLoader}>
        <BeatLoader color="#FF6900" />
      </div>
    )



  return (
    <div className={css.serviceBox}>
      <H4 className={css.serviceHeading}>{servicesTitle}</H4>
      <div className={css.serviceTitle}>
        {servicesContent}

        <Modal
          className={css.advisorModal}
          id="ModalWithPortal"
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            console.log('Closing modal');
          }}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div>
            Advisor is not currently available for 1-hour delivery. Would you like to create a draft order to be used later?
          </div>
          <div className={css.buttonWrapper}>
            <Button className={css.noButton} type="button" onClick={() => setIsModalOpen(false)}>No</Button>
            <Button 
            type="button" 
            inProgress={initiatePrivilegedInProgress}
            ready={initiatePrivileged}
            disabled={initiatePrivilegedInProgress || initiatePrivileged}
            onClick={() => {
              isOwnListing ? window.scrollTo(0, 0) : onInquireRushDelivery(ensuredListing)
            }}>
              Yes
            </Button>
          </div>
        </Modal>

      </div>
    </div >
  )
}

AdvisorServices.prototype = {
  services: array.isRequired
}