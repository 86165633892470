import React, { useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
    LISTING_STATE_PENDING_APPROVAL,
    LISTING_STATE_CLOSED,
    SCHEMA_TYPE_MULTI_ENUM,
    SCHEMA_TYPE_TEXT,
    propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
    LISTING_PAGE_DRAFT_VARIANT,
    LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    LISTING_PAGE_PARAM_TYPE_DRAFT,
    LISTING_PAGE_PARAM_TYPE_EDIT,
    createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
    ensureListing,
    ensureOwnListing,
    ensureUser,
    userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
    H4,
    Page,
    NamedLink,
    NamedRedirect,
    OrderPanel,
    LayoutSingleColumn,
    Modal,
    CouponInput,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
    sendInquiry,
    setInitialValues,
    fetchTimeSlots,
    fetchTransactionLineItems,
    inquireRushDelivery
} from './ListingPage.duck';

import {
    LoadingPage,
    ErrorPage,
    priceData,
    listingImages,
    handleContactUser,
    handleSubmitInquiry,
    handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';

import css from './ListingPageCustom.module.css';
import Listing from './CustomListingPageComponents/Listing';
import OrderForm from './CustomListingPageComponents/OrderForm';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { rushDeliveryService } from '../../config/configListing.js';


const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
    const [inquiryModalOpen, setInquiryModalOpen] = useState(
        props.inquiryModalOpenForListingId === props.params.id
    );

    const [orderModalOpen, setOrderModalOpen] = useState(false);
    const [choosedService, setChoosedService] = useState('')

    const {
        isAuthenticated,
        currentUser,
        getListing,
        getOwnListing,
        intl,
        onManageDisableScrolling,
        params: rawParams,
        location,
        scrollingDisabled,
        showListingError,
        reviews,
        fetchReviewsError,
        sendInquiryInProgress,
        sendInquiryError,
        monthlyTimeSlots,
        onFetchTimeSlots,
        listingConfig: listingConfigProp,
        onFetchTransactionLineItems,
        lineItems,
        fetchLineItemsInProgress,
        fetchLineItemsError,
        history,
        callSetInitialValues,
        onSendInquiry,
        onInitializeCardPaymentData,
        config,
        routeConfiguration,
        onUpdateUserPrivateData,
        updateInProgress,
        updateProfileError,
        onInquireRushDelivery,
        initiatePrivilegedInProgress,
        initiatePrivilegedError,
        initiatePrivileged
        } = props;

    // prop override makes testing a bit easier
    // TODO: improve this when updating test setup
    // const listingConfig = listingConfigProp || config.listing;
    const listingFields = config.listing.customListingFields;
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
        isPendingApprovalVariant || isDraftVariant
            ? ensureOwnListing(getOwnListing(listingId))
            : ensureListing(getListing(listingId));
    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingPathParamType = isDraftVariant
        ? LISTING_PAGE_PARAM_TYPE_DRAFT
        : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'details';

    const isApproved =
        currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
        (isPendingApprovalVariant || isDraftVariant) &&
        showListingError &&
        showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
        return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
        // 404 listing not found
        return <NotFoundPage />;
    } else if (showListingError) {
        // Other error in fetching listing
        return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
    } else if (!currentListing.id) {
        // Still loading the listing
        return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
    }

    const {
        description = '',
        geolocation = null,
        price = null,
        title = '',
        publicData = {},
        metadata = {},
    } = currentListing.attributes;

    const richTitle = (
        <span>
            {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
                longWordClass: css.longWord,
            })}
        </span>
    );

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
        userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const displayName = userDisplayNameAsString(ensuredAuthor, '');
    const fullName = ensuredAuthor.attributes.profile.publicData?.fullName;
    const authorDisplayName = !!fullName ? fullName : displayName;
    const { formattedPrice } = priceData(price, config.currency, intl);

    const commonParams = { params, history, routes: routeConfiguration };


    //We want to show services with their respective amount and description
    //note: services which price is not set should not be included.
    const services = currentListing.attributes.publicData.services || [];
    const prices = currentListing.attributes.publicData.prices || [];
    const priceSetServices = prices.map(p => p.service);
    const filteredServices = services.filter(s => priceSetServices.includes(s.service));

    const servicesWithAmount = filteredServices.map(s => {
        const { service, id, mandatory, deliveryWithin24Hours } = s;
        const priceObject = prices.find(price => price.service === service);
        return {
            id,
            title: service,
            amount: priceObject?.amount,
            mandatory,
            deliveryWithin24Hours
        };
    });

    const serviceFieldConfig = listingFields.filter(f => f.key === 'services')[0];
    const { enumOptions = [] } = serviceFieldConfig || {};
    const servicesWithAmountAndDes = servicesWithAmount.map(swa => {
        const filteredOption = enumOptions.find(optn => optn.id === swa.id);
        return {
            ...swa,
            description: filteredOption ? filteredOption.description : '',
            validity: filteredOption?.validity
        }
    })

    // ////// Adding Rush Delivery Service
    const { isRushDelivery } = ensuredAuthor.attributes.profile.publicData;
    isRushDelivery && servicesWithAmountAndDes.splice(1, 0, rushDeliveryService);


    const savedMessage = currentUser?.attributes?.profile?.privateData?.savedMessage;
    const savedMessageMaybe = savedMessage ? { message: savedMessage } : {};

    const onContactUser = handleContactUser({
        ...commonParams,
        currentUser,
        callSetInitialValues,
        location,
        setInitialValues,
        setInquiryModalOpen,

    });
    // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
    const onSubmitInquiry = handleSubmitInquiry({
        ...commonParams,
        getListing,
        onSendInquiry,
        setInquiryModalOpen,
        onUpdateUserPrivateData
    });
    const onSubmit = handleSubmit({
        ...commonParams,
        currentUser,
        callSetInitialValues,
        getListing,
        onInitializeCardPaymentData,
    });

    const handleOrderSubmit = async (values) => {
        const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
        if (isOwnListing || isCurrentlyClosed) {
            window.scrollTo(0, 0);
        } else {
            //Existence of booking date depends upon service 
            //however, for future possibilities we are taking
            //date but will not consider it right now for 
            //our logic, it is just of no use right now

            const { saveMessage, service, message, images, upsell, bookingStartDate = new Date() } = values;
            const bookingDates = { startDate: bookingStartDate, endDate: bookingStartDate };

            //Determine if the service is deliverable within 24 hours
            const selectedService = servicesWithAmount.filter(swa => swa.title === service)[0];

            const deliveryWithin24Hours = selectedService && selectedService.deliveryWithin24Hours === true;
            //see, if user has chose for upsell or not
            const needUpsell = (upsell && upsell?.length > 0) && (deliveryWithin24Hours !== true) ? true : false;
            const orderData = { bookingDates, service, message, images, needUpsell, deliveryWithin24Hours };

            //save or update message if required.
            if (isAuthenticated && saveMessage && saveMessage.length && saveMessage.length > 0) {
                const updateRes = await onUpdateUserPrivateData({ privateData: { savedMessage: message } });
                if (updateRes && updateRes.status === 200) {
                    return onSubmit(orderData);
                }
            } else {
                onSubmit(orderData)
            }
        }
    };

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = listingImages(
        currentListing,
        `${config.layout.listingImage.variantPrefix}-2x`
    ).map(img => img.url);
    const marketplaceName = config.marketplaceName;
    const schemaTitle = intl.formatMessage(
        { id: 'ListingPage.schemaTitle' },
        { title, price: formattedPrice, marketplaceName }
    );
    // You could add reviews, sku, etc. into page schema
    // Read more about product schema
    // https://developers.google.com/search/docs/advanced/structured-data/product
    const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
    const schemaPriceMaybe = price
        ? {
            price: intl.formatNumber(convertMoneyToNumber(price), {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            priceCurrency: price.currency,
        }
        : {};
    const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
    const schemaAvailability =
        currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

    // const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));


    const handleOnOrder = (service) => {

        if (!isOwnListing) {
            setChoosedService(service)
            setOrderModalOpen(true)
        } else {
            window.scrollTo(0, 0)
        }
    }

    return (
        <Page
            title={schemaTitle}
            scrollingDisabled={scrollingDisabled}
            author={authorDisplayName}
            description={description}
            facebookImages={facebookImages}
            twitterImages={twitterImages}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'Product',
                description: description,
                name: schemaTitle,
                image: schemaImages,
                offers: {
                    '@type': 'Offer',
                    url: productURL,
                    ...schemaPriceMaybe,
                    availability: schemaAvailability,
                },
            }}
        >
            <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
                {currentListing.id ? (
                    <div className={css.actionBar}>
                        <ActionBarMaybe
                            isOwnListing={isOwnListing}
                            listing={currentListing}
                            editParams={{
                                id: listingId.uuid,
                                slug: listingSlug,
                                type: listingPathParamType,
                                tab: listingTab,
                            }}
                        />
                    </div>
                ) : null}

                <div className={css.root}>
                    <div className={css.leftSide}>
                        <SectionAuthorMaybe
                            title={title}
                            listing={currentListing}
                            authorDisplayName={authorDisplayName}
                            onContactUser={onContactUser}
                            isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
                            onCloseInquiryModal={() => setInquiryModalOpen(false)}
                            sendInquiryError={sendInquiryError}
                            sendInquiryInProgress={sendInquiryInProgress}
                            onSubmitInquiry={onSubmitInquiry}
                            currentUser={currentUser}
                            onManageDisableScrolling={onManageDisableScrolling}
                            onChooseReading={() => setOrderModalOpen(true)}
                            initialValues={{ ...savedMessageMaybe }}
                            messageAlreadySaved={!!savedMessage}
                            updateInProgress={updateInProgress}
                            updateProfileError={updateProfileError}
                        />
                    </div>
                    <div className={css.listingBox}>
                        <Listing intl={intl}
                            listing={currentListing}
                            currentUser={currentUser}
                            servicesWithAmountAndDes={servicesWithAmountAndDes}
                            onOrder={handleOnOrder}
                            reviews={reviews}
                            fetchReviewsError={fetchLineItemsError}
                            listingFieldsInProgress={null}
                            listingFieldsError={null}
                            authorDisplayName={authorDisplayName}
                            onInquireRushDelivery={onInquireRushDelivery}
                            isOwnListing={isOwnListing}
                            initiatePrivilegedInProgress={initiatePrivilegedInProgress}
                            initiatePrivilegedError={initiatePrivilegedError}
                            initiatePrivileged={initiatePrivileged}
                        />
                    </div>
                </div>

                <Modal id="ListingPage.order" isOpen={orderModalOpen} onClose={() => setOrderModalOpen(false)} onManageDisableScrolling={onManageDisableScrolling}>
                    <OrderForm intl={intl}
                        onSubmit={handleOrderSubmit}
                        authorDisplayName={authorDisplayName}
                        onFetchTransactionLineItems={onFetchTransactionLineItems}
                        isOwnListing={isOwnListing}
                        listingId={listingId}
                        listing={currentListing}
                        monthlyTimeSlots={monthlyTimeSlots}
                        onFetchTimeSlots={onFetchTimeSlots}
                        lineItems={lineItems}
                        fetchLineItemsError={fetchLineItemsError}
                        fetchLineItemsInProgress={fetchLineItemsInProgress}
                        marketplaceCurrency={config.currency}
                        dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                        marketplaceName={config.marketplaceName}
                        initialValues={{ service: choosedService, ...savedMessageMaybe }}
                        currentUser={currentUser}
                        messageAlreadySaved={!!savedMessage}
                        updateInProgress={updateInProgress}
                        updateProfileError={updateProfileError}
                        isRushDelivery={isRushDelivery}
                    />
                </Modal>
            </LayoutSingleColumn>
        </Page >
    );
};

ListingPageComponent.defaultProps = {
    currentUser: null,
    inquiryModalOpenForListingId: null,
    showListingError: null,
    reviews: [],
    fetchReviewsError: null,
    monthlyTimeSlots: null,
    sendInquiryError: null,
    listingConfig: null,
    lineItems: null,
    fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
    // from useHistory
    history: shape({
        push: func.isRequired,
    }).isRequired,
    // from useLocation
    location: shape({
        search: string,
    }).isRequired,

    // from useIntl
    intl: intlShape.isRequired,

    // from useConfiguration
    config: object.isRequired,
    // from useRouteConfiguration
    routeConfiguration: arrayOf(propTypes.route).isRequired,

    params: shape({
        id: string.isRequired,
        slug: string,
        variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
    }).isRequired,

    isAuthenticated: bool.isRequired,
    currentUser: propTypes.currentUser,
    getListing: func.isRequired,
    getOwnListing: func.isRequired,
    onManageDisableScrolling: func.isRequired,
    scrollingDisabled: bool.isRequired,
    inquiryModalOpenForListingId: string,
    showListingError: propTypes.error,
    callSetInitialValues: func.isRequired,
    reviews: arrayOf(propTypes.review),
    fetchReviewsError: propTypes.error,
    monthlyTimeSlots: object,
    // monthlyTimeSlots could be something like:
    // monthlyTimeSlots: {
    //   '2019-11': {
    //     timeSlots: [],
    //     fetchTimeSlotsInProgress: false,
    //     fetchTimeSlotsError: null,
    //   }
    // }
    sendInquiryInProgress: bool.isRequired,
    sendInquiryError: propTypes.error,
    onSendInquiry: func.isRequired,
    onInitializeCardPaymentData: func.isRequired,
    listingConfig: object,
    onFetchTransactionLineItems: func.isRequired,
    lineItems: array,
    fetchLineItemsInProgress: bool.isRequired,
    fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
    const config = useConfiguration();
    const routeConfiguration = useRouteConfiguration();
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();

    return (
        <ListingPageComponent
            config={config}
            routeConfiguration={routeConfiguration}
            intl={intl}
            history={history}
            location={location}
            {...props}
        />
    );
};

const mapStateToProps = state => {
    const { isAuthenticated } = state.auth;
    const { updateInProgress, updateProfileError } = state.ProfileSettingsPage;
    const {
        showListingError,
        reviews,
        fetchReviewsError,
        monthlyTimeSlots,
        sendInquiryInProgress,
        sendInquiryError,
        lineItems,
        fetchLineItemsInProgress,
        fetchLineItemsError,
        inquiryModalOpenForListingId,
        initiatePrivilegedInProgress,
        initiatePrivilegedError,
        initiatePrivileged
    } = state.ListingPage;
    const { currentUser } = state.user;


    const getListing = id => {
        const ref = { id, type: 'listing' };
        const listings = getMarketplaceEntities(state, [ref]);
        return listings.length === 1 ? listings[0] : null;
    };

    const getOwnListing = id => {
        const ref = { id, type: 'ownListing' };
        const listings = getMarketplaceEntities(state, [ref]);
        return listings.length === 1 ? listings[0] : null;
    };

    return {
        isAuthenticated,
        currentUser,
        getListing,
        getOwnListing,
        scrollingDisabled: isScrollingDisabled(state),
        inquiryModalOpenForListingId,
        showListingError,
        reviews,
        fetchReviewsError,
        monthlyTimeSlots,
        lineItems,
        fetchLineItemsInProgress,
        fetchLineItemsError,
        sendInquiryInProgress,
        sendInquiryError,
        updateInProgress,
        updateProfileError,
        initiatePrivilegedInProgress,
        initiatePrivilegedError,
        initiatePrivileged
    };
};

const mapDispatchToProps = dispatch => ({
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
    callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
        dispatch(setInitialValues(values, saveToSessionStorage)),
    onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
    onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
    onFetchTimeSlots: (listingId, start, end, timeZone) =>
        dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
    onUpdateUserPrivateData: (data) => dispatch(updateProfile(data)),
    onInquireRushDelivery: (listing) => dispatch(inquireRushDelivery(listing)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(EnhancedListingPage);

export default ListingPage;