import React from 'react'
import ListingNavigation from './ListingNavigation'
import { Element } from 'react-scroll';


import css from './Listing.module.css'
import { H1, H3, H4, H6 } from '../../../components';
import { ensureCurrentUser, ensureListing, ensureUser } from '../../../util/data';
import AdvisorInformation from './AdvisorInformation';
import AdvisorServices from './AdvisorServices';
import MeetAdvisor from './MeetAdvisor';
import SectionReviews from '../SectionReviews';

const ADVISORINFORMATIONTABID = 'advisorInformation';
const ADVISORSERVICESTABID = 'advisorServices';
const MEETMETABID = 'meetMe';
const REVIEWSTABID = 'reviews';
const REFUNDTABID = 'refunds';

const tabs = [
    { tab: 'Advisor Information', link: ADVISORINFORMATIONTABID },
    { tab: 'Advisor Services', link: ADVISORSERVICESTABID },
    { tab: 'Meet Me', link: MEETMETABID },
    { tab: 'Reviews', link: REVIEWSTABID },
    { tab: 'Refund', link: REFUNDTABID },
]

export default function Listing(props) {
    const {
        intl,
        listing,
        currentUser,
        servicesWithAmountAndDes,
        onOrder,
        reviews,
        fetchReviewsError,
        listingFieldsInProgress,
        authorDisplayName,
        onInquireRushDelivery,
        isOwnListing,
        initiatePrivilegedInProgress,
        initiatePrivilegedError,
        initiatePrivileged
    } = props;


    const ensuredListing = ensureListing(listing);

    const user = ensuredListing.author;
    const userIsCurrentUser = user && user.type === 'currentUser';
    const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const isCurrentUser =
        ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;

    const { isOnline } = ensuredListing?.attributes?.publicData;


    const listingHeading = intl.formatMessage({ id: 'CustomListingPage.Listing.heading' });
    const refundTitle = intl.formatMessage({ id: 'CustomListingPage.Listing.RefundTitle' })
    const refundText = intl.formatMessage({ id: 'CustomListingPage.Listing.RefundText' })

    return (
        <div className={css.root}>
            {/* Header */}
            <div className={css.listingHeader}>
                <H4>{authorDisplayName}</H4>
            </div>

            {/* Navigation */}
            {/* <ListingNavigation tabs={tabs} /> */}

            {/* Advisor Information Section */}
            <Element name={ADVISORINFORMATIONTABID}>
                <AdvisorInformation listing={ensuredListing} intl={intl} />
            </Element>

            {/* Advisor Services Section */}
            <Element name={ADVISORSERVICESTABID}>
                <AdvisorServices services={servicesWithAmountAndDes}
                    ensuredListing={ensuredListing}
                    ensuredCurrentUser={ensuredCurrentUser}
                    isOnline={isOnline}
                    ensuredUser={ensuredUser}
                    intl={intl} onOrder={onOrder}
                    listingFieldsInProgress={listingFieldsInProgress}
                    onInquireRushDelivery={onInquireRushDelivery}
                    isOwnListing={isOwnListing}
                    initiatePrivilegedInProgress={initiatePrivilegedInProgress}
                    initiatePrivilegedError={initiatePrivilegedError}
                    initiatePrivileged={initiatePrivileged}
                />
            </Element>

            {/* Meet Advisor Section */}
            <Element name={MEETMETABID}>
                <MeetAdvisor listing={ensuredListing} intl={intl} />
            </Element>

            {/* Reviews Section */}
            <Element name={REVIEWSTABID}>
                <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
            </Element>

            {/* Refunds Section */}
            <Element name={REFUNDTABID}>
                <div className={css.refundWrapper}>
                    <div className={css.refundHeading}>{refundTitle}</div>
                    <p className={css.refundDetails}>{refundText}</p>
                </div>
            </Element>
        </div>
    )
}
