import React from 'react'
import { H4, H6 } from '../../../components'
import { ensureCurrentUser, ensureUser } from '../../../util/data';
import css from './Listing.module.css'

function DisplayDescription({ description }) {
    const formattedDescription = description ? description.split('\n').map((paragraph, index) => (
        <p className={css.paragraph} key={index}>{paragraph}</p>
    )) : null;

    return <div>{formattedDescription}</div>;
}



export default function AdvisorInformation(props) {
    const { listing, intl } = props;

    const user = listing.author;
    const userIsCurrentUser = user && user.type === 'currentUser';
    const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

    const description = listing.attributes.description;
    const yearsOfExperience = ensuredUser.attributes.profile.publicData?.experience;
    const specialities = listing.attributes.publicData.categories || [];

    const specialitiesContent = <ul className={css.specialitiesBox}>
        {specialities.map((s, i) => (
            <li key={i} className={css.specialitiesCard}>{s}</li>
        ))}
    </ul>

    const experienceTitle = intl.formatMessage({ id: 'CustomListingPage.Listing.authorExperience' });
    const specialityTitle = intl.formatMessage({ id: 'CustomListingPage.Listing.authorSpeciality' });

    return (
        <div className={css.informationContainer}>
            <DisplayDescription description={description} />
            <div className={css.subInformationB}>
                <div>
                    <H4>{experienceTitle}</H4>
                    <div className={css.specialitiesDeatail}>{yearsOfExperience}</div>
                </div>
                <div>
                    <H4>{specialityTitle}</H4>
                    <div className={css.specialitiesDeatail}>{specialitiesContent}</div>
                </div>
            </div>
        </div>
    )
}